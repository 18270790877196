import { useEffect, useState } from "react";
import { Button} from "react-bootstrap"
import LocalMsgModalSpinner from "../../../../components/LocalMsgModalSpinner";
import { httpApiPostText } from "../../../../lib";
import * as env from '../../../../env';
import MsgModalDialogEspecial from "../../../../components/MsgModalDialogEspecial";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

const Resumen = (props: any) =>{
    const [sHCarga, setSHCarga] = useState(false);  
    let [records, setRecords] = useState(props.data);  
    const [msgRespuestInv, setMsgRespuestInv] = useState("");  
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    let emp: any = useSelector((state: any) => state.emp);     

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    }; 
    
    const MsgDlghandlerResponseApi = () =>{
        setMsgDlgShowApiResponse(false);
    }  

    const recargarClasificaciones = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionesInventario?Pais=CL&Usuario=${emp.user}`;     
        const response = await httpApiPostText(recurso,"PUT", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, {});
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);
        if (response.code >= 400){
            setMsgRespuestInv("No se pudo realizar la opreración");
        }else{
            setMsgRespuestInv(arrDta);
        }
        setMsgDlgShowApiResponse(true);
    }

    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
          setRecords([...props.data]);  
        }else{
            records = [...props.data];
            let reg = records.filter((dato: any)=> {
                return (dato.Subinventario_Id && dato.Subinventario_Id.includes(evnt.target.value.toUpperCase()))
                       || (dato.Negocio && dato.Negocio.includes(evnt.target.value.toUpperCase()))
                       || (dato.Seleccion.props.children && dato.Seleccion.props.children.includes(evnt.target.value.toUpperCase())) 
                       || (dato.Cant_Sku && dato.Cant_Sku.toString().includes(evnt.target.value.toUpperCase()))  
                       || (dato.Estado && dato.Estado.toString().includes(evnt.target.value.toUpperCase())) 
                       || (dato.Apertura_Fecha && dato.Apertura_Fecha.includes(evnt.target.value.toUpperCase())) 
                       || (dato.Usuario_Apertura && dato.Usuario_Apertura.includes(evnt.target.value.toUpperCase())) 
                       || (dato.Publicado_Fecha && dato.Publicado_Fecha.includes(evnt.target.value.toUpperCase()) )
                       || (dato.Bajada_Saldos && dato.Bajada_Saldos.toString().includes(evnt.target.value.toUpperCase()))
                       || (dato.Bajada_Usuario && dato.Bajada_Usuario.toString().includes(evnt.target.value.toUpperCase())) 
                       || (dato.Porc_Cuadratura && dato.Porc_Cuadratura.toString().includes(evnt.target.value.toUpperCase()));                        
            }); 
            setRecords([...reg]);              
        }
    }        

    useEffect(()=>{
        setRecords(props.data);

    }, [props.data]);

    return(
        <>
            <div className="row mb-4 mt-4">
                <div className="col col-lg-6 ">
                    <Button type="button" className="me-1 border-0" style={{backgroundColor:"#2DCEE3"}} onClick={()=>recargarClasificaciones()}
                    disabled={records.length <= 0}
                    >
                        Recargar calificaciones
                    </Button>
                </div>              
            </div>
            <div className="d-flex flex-column col col-lg-6 col-md-12 mb-3 ">
                <label className="m-1">Filtro</label>
                <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
            </div>              
            <DataTable
                columns={props.columns}
                data={records}
                selectableRowsHighlight
                fixedHeader
                pagination
                highlightOnHover
                fixedHeaderScrollHeight="600px"
                paginationComponentOptions={pagOptions}
            />  
            {/*********** cuadro de dialogo para msj de api **********/}
            <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Gestión inventario`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApi}
                HanlerdClickNok={null}
            />   
                     
            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />                          
        </>
    )
};

export default Resumen;
import { Container } from "react-bootstrap";
import BarraMenuHz from "../../components/BarraMenoHz";

const LandingPage = () => {

    return(
        <>
            <div className="container-fluid">
                <BarraMenuHz/>   
                <Container fluid className="text-center" >
                    <label style={{fontSize:"28px", color:"#00B5B8"}}>BIENVENIDO</label>
                    <br />
                    <label style={{fontSize:"24px", color:"#00B5B8"}}>Sistema de Control y Gestión de Inventario</label>
                </Container>                               
            </div>
        
        </>
    )

};

export default LandingPage;
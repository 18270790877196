import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useEffect, useState } from 'react';
import { httpApiGetText, httpApiPostText } from '../../../../lib';
import * as env from '../../../../env';
import MsgDialog from '../../../../components/MsgDialog';
import DataTable from 'react-data-table-component';
import { FaRegTrashAlt } from 'react-icons/fa';
import MsgDialog3 from '../../../../components/MsgDialog3';

let columns = [
    { name: "Clasificación", selector: (row:any) => row.DESCRIPCION, sortable: true, width:"200px"},
    { name: "Motivo", selector: (row:any) => row.MOTIVO_DESCRIPCION, sortable: true, width:"200px"},
    { name: "Sub Motivo", selector: (row:any) => row.SUB_DESCRIPCION, sortable: true, width:"200px"},    
    { name: "Cantidad", selector: (row:any) => row.MUE_DET_CANTIDAD, sortable: true, width:"150px", right:true},                           
    { name: "Acciones", selector: (row:any) => row.Acciones, grow:2},    
];

const MsgDialogDiferencia = (props: {Show: boolean, obj: any,  HandlerClickOk: any, form: any}) => {
             
    let [clasificacion, setClasificacion] = useState(new Array());   
    let [subCalsificacion, setsubCalsificacion] = useState(new Array());       
    let [subMotivo, setSubMotivo] = useState(new Array());      
    let [subtipo, setSubTipo] = useState("");       
    let [tipo, setTipo] = useState("");      
    let [subTipoMotivo, setSubTipoMotivo] = useState("");             
    let [cantidad, setCantidad] = useState(0);   
    let [records, setRecords] = useState(new Array<any>());      
    const [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    const [MsgApiResponse, setMsgApiResponse] = useState("");     
    const [showMsgApiError, setShowMsgApiError] = useState(false); 
    const [MsgApiError, setMsgApiError] = useState("");     
    let [diferencia, setDiferencia] = useState("");       
    let [clasificar, setClasificar] = useState("");         
    let [clasificado, setClasificado] = useState("");   


    const OnSelectChange = async (evnt: any) =>{

        tipo = evnt.target.value;
        setTipo(tipo);        
        if (tipo === ""){
            subCalsificacion = subMotivo = [];
            setsubCalsificacion([...subCalsificacion]);      
            setSubMotivo([...subMotivo]); 
            setSubTipo("");
            setSubTipoMotivo("");                        
        }else{
            // Llamado al servicio que trae los subtipos    
            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraMotivos/BuscarMuestraMotivos?paramClasif=${tipo}`;            
            const response = await httpApiGetText(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const dta = await JSON.parse(response.message);
            subCalsificacion = [...dta];
            subMotivo = [];
            setSubMotivo([...subMotivo]);     
        }

        setsubCalsificacion([...subCalsificacion]);      
    }

    const HnadlerSubtipo = async (evnt: any) =>{
        subtipo = evnt.target.value;
        setSubTipo(subtipo);
        if (subtipo === ""){
            subMotivo = [];
            setSubTipoMotivo("");            
        }else{
            // Llamado al servicio que trae los submotivos  
            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraMotivos/BuscarMuestraSubMotivos?paramClasif=${tipo}&paramMotivo=${subtipo}`;            
            const response = await httpApiGetText(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const dta = await JSON.parse(response.message);
            subMotivo = [...dta];
            setSubTipoMotivo("");            
        }
        setSubMotivo([...subMotivo]);  
    }

    const HnadlerSubtipoMotivo = async (evnt: any) =>{
        subTipoMotivo = evnt.target.value;
        setSubTipoMotivo(subTipoMotivo); 
    }
   
    const HnadlerCantidad = (evnt: any) =>{
        cantidad = evnt.target.value;
        setCantidad(cantidad);
    }   
    
    const mostarTabla = async () =>{
   
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraList?PAIS=CL&ANNO=${props.form.Anno}&MES=${props.form.Mes}&CENTRO=${props.form.Centro}` +
                        `&ALMACEN=${props.form.Almacen}&INV_ITEM_ID=${props.obj.Codigo_Rosen}&ESPECIAL=&MUESTRA_REF=${props.form.Muestra}`;     
          
        const response = await httpApiGetText(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message);      
        if(response.code >= 400){
            setMsgApiError(arrDta.Message);
            setShowMsgApiError(true);
        }else{
            diferencia = arrDta.Diferencia;
            setDiferencia(diferencia);

            clasificar = arrDta.Clasificar;
            setClasificar(clasificar);

            clasificado = arrDta.Clasificado;
            setClasificado(clasificado);

            const auxDta:any = [];
            arrDta.Detalle.map((obj: any, idx: number)=>{
                const newObj = {
                    DESCRIPCION: obj.DESCRIPCION,
                    SUB_DESCRIPCION: obj.SUB_DESCRIPCION,
                    MUE_DET_CANTIDAD: obj.MUE_DET_CANTIDAD,
                    MOTIVO_DESCRIPCION: obj.MOTIVO_DESCRIPCION,
                    Acciones: <div >
                                    <a href="#!" id={`${idx}-${1}`} onClick={()=>{
                                            eliminaDiferencia(obj);
                                        }}>
                                        <FaRegTrashAlt title="Eliminar" color={'white'}  className='bg-danger rounded-circle p-1 h4'/>   
                                    </a> 
                              </div>                    
                };
                auxDta.push(newObj);
            });
            records = [...auxDta];
            setRecords([...records]);
        }    
    }       

    const hendlerAdd = async () =>{
        
        const req = {
            MUE_DET_PAIS: "CL",
            MUE_DET_ANNO: props.form.Anno,
            MUE_DET_MES: props.form.Mes,
            MUE_DET_CENTRO: props.form.Centro,
            MUE_DET_ALMACEN: props.form.Almacen,
            MUE_DET_INV_ITEM_ID: props.obj.Codigo_Rosen,
            MUE_MUESTRA_REF: props.form.Muestra,
            MUE_DET_ESPECIAL: props.obj.Especial,
            MUE_DET_ID_MOTIVO: subTipoMotivo,
            MUE_DET_ID_CLASIF: tipo,
            MUE_DET_CANTIDAD : cantidad               
        };  

        const bdy = [req];
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra`;     
        const response = await httpApiPostText(recurso, "PUT", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        const arrDta = await JSON.parse(response.message);   
        if(response.code >= 400){
            setMsgApiError(arrDta.Message);
            setShowMsgApiError(true);
        }else{
            mostarTabla(); 
        }        
    }      

    const eliminaDiferencia = async(obj: any) => {

        const req = {
            MUE_DET_PAIS: obj.MUE_DET_PAIS,
            MUE_DET_ANNO: obj.MUE_DET_ANNO,
            MUE_DET_MES: obj.MUE_DET_MES,
            MUE_DET_CENTRO: obj.MUE_DET_CENTRO,
            MUE_DET_ALMACEN: obj.MUE_DET_ALMACEN,
            MUE_DET_INV_ITEM_ID: obj.MUE_DET_INV_ITEM_ID,
            MUE_MUESTRA_REF: props.form.Muestra,
            MUE_DET_ESPECIAL: obj.MUE_DET_ESPECIAL,
            MUE_DET_ID_MOTIVO: obj.MUE_DET_ID_MOTIVO,
            MUE_DET_ID_CLASIF: obj.MUE_DET_ID_CLASIF
        };

        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra`;        
        const response = await httpApiPostText(recurso, "DELETE", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, req);
        const arrDta = await JSON.parse(response.message);   
    
        if(response.code >= 400){
            setMsgApiError(arrDta.Message);
            setShowMsgApiError(true);
        }else{
            mostarTabla();
        }
    }    

    useEffect(()=>{

        const init = async () =>{
            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraList/BuscarClasifMuestra?paramClasif=0`;     
            const response = await httpApiGetText(recurso,{
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const arrDta = await JSON.parse(response.message); 
            clasificacion = [...arrDta];
            setClasificacion([...clasificacion]);
        }
  
        init();
        mostarTabla();
    }, []);    

 return (
      <div>
        <Modal show={props.Show} centered={true} size="xl" >
            <Modal.Header  className=' bg-success' >
                <Modal.Title className='h3 text-center text-light'>
                    <h3>Clasificación por ítem</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className=' row m-3'>
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                <div className=' fw-bolder'>
                                    Código Material
                                </div>
                                <div>
                                    {props.obj.Codigo_Rosen}
                                </div>                                
                            </div>
                        </div>
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Descripción
                                    </div>
                                    <div>
                                        {props.obj.Descripcion}
                                    </div>                                
                                </div>
                        </div>                        
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Especial
                                    </div>
                                    <div>
                                        {props.obj.Especial}
                                    </div>                                
                            </div>     
                        </div>
                    </div>  
                    <div className=' row m-3'>
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className='fw-bolder'>
                                        Diferencia
                                    </div>
                                    <div className=''>
                                        {diferencia}
                                    </div>                                
                            </div>  
                        </div>   
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Clasificar
                                    </div>
                                    <div className=''>
                                        {clasificar}
                                    </div>                                
                            </div>  
                        </div>                          
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Clasificado
                                    </div>
                                    <div className=''>
                                        {clasificado}
                                    </div>                                
                            </div>  
                        </div>                    
                    </div>                                          
                    <hr />
                    <div className=' row m-3'>
                        <div className='col col-lg-3 col-md-12'>
                            <div>Clasificación</div>
                            <div>
                                <select  value={tipo} id="tipo" className="form-select mt-3 col-md-12 col-lg-6" onChange={OnSelectChange} >
                                    <option key={-3} value={""}>Seleccione tipo</option>                                                                            
                                    {
                                        clasificacion.map((obj: any, idx:number)=> <option key={idx} value={obj.MUE_ID_CLASIFICACION}>{obj.MUE_DESCRIPCION}</option>)
                                    }
                                </select >
                            </div>
                        </div>
                        <div className='col col-lg-2 col-md-12'>
                            <div>
                                Motivos
                            </div>
                            <div>
                                <select value={subtipo} id="formClasificacion" className="form-select mt-3 col-md-12 col-lg-6" onChange={HnadlerSubtipo} >
                                    <option key={-3} value={""}>Seleccione Subtipo</option>                                                                            
                                    {
                                        subCalsificacion.map((obj: any, idx:number)=> <option key={idx} value={obj.MUE_MOT_ID_MOTIVO}>{obj.MUE_MOT_DESCRIPCION}</option>)
                                    }
                                </select >
                            </div>                            
                        </div>   
                        <div className='col col-lg-3 col-md-12'>
                            <div>
                                Sub motivo
                            </div>
                            <div>
                                <select value={subTipoMotivo} id="formClasificacion" className="form-select mt-3 col-md-12 col-lg-6" onChange={HnadlerSubtipoMotivo} >
                                    <option key={-3} value={""}>Seleccione Sub motivo</option>                                                                            
                                    {
                                        subMotivo.map((obj: any, idx:number)=> <option key={idx} value={obj.MUE_SUB_ID_MOTIVO}>{obj.MUE_SUB_DESCRIPCION}</option>)
                                    }
                                </select >
                            </div>                            
                        </div>                                                
                        <div className='col col-lg-1 col-md-12'>
                            <div>
                                Cantidad
                            </div>
                            <div>
                                <input type="number" id="cantidad" size={8}  className="form-control mt-3 col-md-12 col-lg-6 text-end"
                                    value={cantidad}
                                    onChange={HnadlerCantidad} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>                            
                        </div>
                        <div className='col col-lg-3 col-md-12 d-flex align-items-end justify-content-center'>
                            <div>
                                <Button className='btn btn-success' onClick={hendlerAdd}
                                    disabled={(tipo === "") || (subtipo === "") || (cantidad === 0)}
                                > + Agrega clasificación
                                </Button>                                
                            </div>
                        </div>   
                    </div>    
                    <hr />   
                    <div>
                        <DataTable
                            columns={columns}
                            data={records}                
                            selectableRowsHighlight
                            fixedHeader
                            pagination
                            highlightOnHover
                        />                            
                    </div>                                 
            </Modal.Body>                
            <Modal.Footer className=''>
                <Button type="button" className="btn btn-success btnColorOk" onClick={()=>props.HandlerClickOk(clasificar, clasificado)}>
                    Cerrar
                </Button>                                 
            </Modal.Footer>
        </Modal>
        {showMsgApiResponse && <MsgDialog 
                Title="Clasificación Diferencias"
                Icon="!"
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerClickOk={()=>setShowMsgApiResponse(false)}
                HanlerdClickNok={null}
            /> } 
            {showMsgApiError && <MsgDialog3 
                Title="Clasificación Diferencias"
                Icon="!"
                Show={showMsgApiError}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiError}
                HandlerClickOk={()=>setShowMsgApiError(false)}
                HanlerdClickNok={null}
            />}                   
      </div>
  );
}

export default MsgDialogDiferencia;
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useEffect, useState } from 'react';
import { httpApiGetText, httpApiPostText } from '../../../../lib';
import * as env from '../../../../env';
import MsgDialog from '../../../../components/MsgDialog';

const MsgDialogMasiva = (props: {Show: boolean, arrCodigos: any,   muestraRef: any, HandlerClickOk: any, form: any, actionOK: any, actionNOK: any}) => {
             
    let [clasificacion, setClasificacion] = useState(new Array());   
    let [subCalsificacion, setsubCalsificacion] = useState(new Array());       
    let [subtipo, setSubTipo] = useState("");       
    let [tipo, setTipo] = useState("");          
    let [subMotivo, setSubMotivo] = useState(new Array());           
    let [subTipoMotivo, setSubTipoMotivo] = useState(""); 
    const [showCuadroDialogo, setShowCuadroDialogo] = useState(false);     

    const OnSelectChange = async (evnt: any) =>{

        tipo = evnt.target.value;
        setTipo(tipo);
        if (tipo === ""){
            subCalsificacion = subMotivo = [];
            setsubCalsificacion([...subCalsificacion]);
            setSubMotivo([...subMotivo]);
            setSubTipo("");
            setSubTipoMotivo("");
        }else{
            // Llamado al servicio que trae los subtipos    
            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraMotivos/BuscarMuestraMotivos?paramClasif=${tipo}`;            
            const response = await httpApiGetText(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const dta = await JSON.parse(response.message);

            subCalsificacion = [...dta];            

            subMotivo = [];
            setSubMotivo([...subMotivo]);            
        }
        setsubCalsificacion([...subCalsificacion]);
    }

    const HnadlerSubtipo = async (evnt: any) =>{
        subtipo = evnt.target.value;
        setSubTipo(subtipo);
        if (subtipo === ""){
            subMotivo = [];
            setSubTipoMotivo("");
        }else{
            // Llamado al servicio que trae los submotivos  
            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraMotivos/BuscarMuestraSubMotivos?paramClasif=${tipo}&paramMotivo=${subtipo}`;            
            const response = await httpApiGetText(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const dta = await JSON.parse(response.message);
            subMotivo = [...dta];

            setSubTipoMotivo("");
        }
        setSubMotivo([...subMotivo]);  

    }

    const HnadlerSubtipoMotivo = async (evnt: any) =>{
        subTipoMotivo = evnt.target.value;
        setSubTipoMotivo(subTipoMotivo); 
    }
   
    const hendlerAdd = async () =>{

        const bdy: any = [];
        props.arrCodigos.map(async (item: any)=>{
            const req = {
                MUE_DET_PAIS: "CL",
                MUE_DET_ANNO: props.form.Anno,
                MUE_DET_MES: props.form.Mes,
                MUE_DET_CENTRO: props.form.Centro,
                MUE_DET_ALMACEN: props.form.Almacen,
                MUE_MUESTRA_REF: props.muestraRef,
                MUE_DET_INV_ITEM_ID: item.Codigo,
                MUE_DET_ESPECIAL: item.E,
                MUE_DET_ID_MOTIVO: subTipoMotivo,
                MUE_DET_ID_CLASIF: tipo,
                MUE_DET_CANTIDAD : item.Clasificar
            };  
            bdy.push(req);
        });
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra`;     
        const response = await httpApiPostText(recurso, "PUT", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);

        const arrDta = await JSON.parse(response.message);   
            
        if(response.code >= 400){
            props.HandlerClickOk(); // cierra el modal
            props.actionNOK(arrDta.Message); // llama a función de error
            return;
        }

        props.HandlerClickOk(); // cierra el modal
        props.actionOK(); // llama a función que actualiza la grilla de la página principal

    }      

    useEffect(()=>{

        const init = async () =>{
            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraList/BuscarClasifMuestra?paramClasif=0`;     
            const response = await httpApiGetText(recurso,{
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const arrDta = await JSON.parse(response.message); 
            clasificacion = [...arrDta];
            setClasificacion([...clasificacion]);
        }

        init();
    }, []);    

 return (
      <div>
        <Modal show={props.Show} centered={true} size="xl" >
            <Modal.Header  className=' bg-success' >
                <Modal.Title className='h3 text-center text-light'>
                    <h3>Clasificación Masiva</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className=' row m-3'>
                        <div className='col col-lg-3 col-md-12'>
                            <div>Clasificación</div>
                            <div>
                                <select  value={tipo} id="tipo" className="form-select mt-3 col-md-12 col-lg-4" onChange={OnSelectChange} >
                                    <option key={-3} value={""}>Seleccione una opción</option>
                                    {
                                        clasificacion.map((obj: any, idx:number)=> <option key={idx} value={obj.MUE_ID_CLASIFICACION}>{obj.MUE_DESCRIPCION}</option>)
                                    }                                    
                                </select >
                            </div>
                        </div>
                        <div className='col col-lg-3 col-md-12'>
                            <div>
                                Motívo
                            </div>
                            <div>
                                <select value={subtipo} id="subtipo" className="form-select mt-3 col-md-12 col-lg-6" onChange={HnadlerSubtipo} >
                                    <option key={-3} value={""}>Seleccione motivo</option>                                                                            
                                    {
                                        subCalsificacion.map((obj: any, idx:number)=> <option key={idx} value={obj.MUE_MOT_ID_MOTIVO}>{obj.MUE_MOT_DESCRIPCION}</option>)
                                    }
                                </select >
                            </div>                            
                        </div>   
                        <div className='col col-lg-3 col-md-12'>
                            <div>
                                Sub motivo
                            </div>
                            <div>
                                <select value={subTipoMotivo} id="formClasificacion" className="form-select mt-3 col-md-12 col-lg-6" onChange={HnadlerSubtipoMotivo} >
                                    <option key={-3} value={""}>Seleccione Sub motivo</option>                                                                            
                                    {
                                        subMotivo.map((obj: any, idx:number)=> <option key={idx} value={obj.MUE_SUB_ID_MOTIVO}>{obj.MUE_SUB_DESCRIPCION}</option>)
                                    }
                                </select >
                            </div>                            
                        </div>                         
                        <div className='col col-lg-3 col-md-12 d-flex align-items-end justify-content-center'>
                            <Button className='btn btn-success'  onClick={()=>setShowCuadroDialogo(true)}
                                disabled={(tipo === "") || (subtipo === "")  || (subTipoMotivo === "")}
                            > + Ingresar clasificación
                            </Button>
                        </div>                     
                    </div>                                   
            </Modal.Body>                
            <Modal.Footer className=''>
                <Button type="button" className="btn btn-danger btnColorOk" onClick={props.HandlerClickOk}>
                    Cerrar
                </Button>                                 
            </Modal.Footer>
        </Modal>     
            {showCuadroDialogo && <MsgDialog 
                Title="Clasificación masiva"
                Icon="!"
                Show={showCuadroDialogo}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="¿Está seguro de realizar la clasificación de todos los ítems seleccionados?"
                HandlerClickOk={hendlerAdd}
                HanlerdClickNok={()=>setShowCuadroDialogo(false)}
            /> }                             
      </div>
  );
}

export default MsgDialogMasiva;
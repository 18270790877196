
import * as env from '../../../../env';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { httpApiDelete, httpApiDownloadFile, httpApiGetText, httpApiPostParams } from '../../../../lib';
import DataTable from 'react-data-table-component';
import { FaDownload, FaRegTrashAlt } from 'react-icons/fa';
import MsgDialog from '../../../../components/MsgDialog';
import MsgDialog3 from '../../../../components/MsgDialog3';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import { useSelector } from 'react-redux';

let columns = [
  { name: "Nombre archivo", selector: (row:any) => row.IDM_NOMBRE_ARCHIVO, sortable: true, grow: 3, wrap: true},
  { name: "Archivo upload", selector: (row:any) => row.IDM_ARCHIVO_UPLOAD, sortable: true, grow: 5, wrap: true},
  { name: "Fecha subida", selector: (row:any) => row.IDM_FECHA_SUBIDA, sortable: true, grow: 2, wrap:true},
  { name: "Usuario", selector: (row:any) => row.IDM_USUARIO, sortable: true, grow: 3},
  { name: "Acciones", selector: (row:any) => row.Acciones, grow:2},       
];

const pagOptions = {
  rowsPerPageText: "Filas por páginas",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos"
};  

const SubirArchivoNube = (
            props: {Data: any, Show: boolean, Title: string, HandlerPreselected: any, 
                    BtnNokName: string, HanlerdClickNok: any, data: any
                    }) =>{

  let emp: any = useSelector((state: any) => state.emp);
  let [fileName, setFilename] = useState("");
  let [data, setData] =  useState(new Array<any>());
  const [showCuadroDialogo, setShowCuadroDialogo] = useState(false);   
  const [showMsgApiError, setShowMsgApiError] = useState(false);  
  const [sHCarga, setSHCarga] = useState(false);     
  const [msgApi, setMsgApi] = useState("");     
  const [fileList, setFileList] = useState<FileList | null>(null);  

  const handleClose = () =>{
    props.HanlerdClickNok();
  }

  const ListadoArchivos = async () =>{

    const recurso = `${env.REACT_APP_API_URL}/api/ArchivosClasifMotivos?Pais=CL&Anno=${props.data.Anno}&Mes=${props.data.Mes}&Centro=${props.data.Centro}&Almacen=${props.data.Almacen}`;     
    const response = await httpApiGetText(recurso,{
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
    });
    const arrDta = await JSON.parse(response.message);     
    if (response.code >= 400){
      console.log("Se ha presentado un problema al consultar el listado de archivos");
    }else{
      const auxDta:any = []; 
      arrDta.map((obj: any, index:number)=>{
        const newObj = {
          ...obj,
          Acciones: <div className=' d-flex gap-3' >
                        <a href="#!" id={`${index}-${1}`} onClick={()=>{
                                setFilename(obj.IDM_NOMBRE_ARCHIVO);
                                setShowCuadroDialogo(true);
                            }}>
                            <FaRegTrashAlt title="Ver" color={'white'}  className='bg-danger rounded-circle p-1 h4'/>   
                        </a> 
                        <a href="#!" id={`${index}-${1}`} onClick={()=>{
                                descargaArchivo(obj.IDM_NOMBRE_ARCHIVO, true);
                            }}>
                            <FaDownload title="Ver" color={'white'}  className='bg-success rounded-circle p-1 h4'/>   
                        </a>                         
                    </div>
        }
        auxDta.push(newObj);        
      });
      data = [...auxDta];
      setData([...data]);      
    }
  }  

  const OnEliminar = async () =>{

    setShowCuadroDialogo(false);

    const recurso = `${env.REACT_APP_API_URL}/api/ArchivosClasifMotivos?Pais=CL&Anno=${props.data.Anno}&Mes=${props.data.Mes}&Centro=${props.data.Centro}&Almacen=${props.data.Almacen}&NombreArchivo=${fileName}`;     
    const response = await httpApiDelete(recurso, "Delete", {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
    });
    if (response.code >= 400){
      setMsgApi("Se ha presentado un error al momento de ejecutar la operación");      
    }else{
      setMsgApi("Archivo eliminado con éxito!!!");
    }
    setShowMsgApiError(true);    

    ListadoArchivos();
  }  

  const download = (file: any) => {
    const link = document.createElement('a')
    const url = URL.createObjectURL(file)
  
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
  
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const descarga = (data: any, fileName: string) =>{

    const reader = new FileReader();        

    reader.onload = (evt: any) => {

        const fl = new File([evt.target.result], fileName, {type: "application/octet-stream"});
        download(fl);
    };      

    reader.readAsArrayBuffer(data);
  }

  const descargaArchivo = async (nmFile: string, todos: boolean) => {
    setSHCarga(true);
    const recurso = `${env.REACT_APP_API_URL}/api/ArchivosClasifMotivos?Pais=CL&Anno=${props.data.Anno}&Mes=${props.data.Mes}&Centro=${props.data.Centro}&Almacen=${props.data.Almacen}&NombreArchivo=${nmFile}`;      
    const response = await httpApiDownloadFile(recurso, {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
    });
    if (response.code >= 400){
      //setMsgApi(JSON.parse(response.message).Message);   
      setMsgApi("Se ha presentado un error al momento de descargar el archivo!!!");  
    }else{
      const data: any = (response.message); 
      descarga(data, nmFile);
      setMsgApi("El archivo se ha descargado con éxito!!!");         
    }
    setShowMsgApiError(true);
    setSHCarga(false);
  }  

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
    setFilename("" + e.target.files?.length);
  }
  
  const handleUploadClick =async () => {

    setSHCarga(true);

    if (!fileList) {
      return;
    }

    const data = new FormData();

    for (let idx = 0; idx < fileList.length; ++idx){
      data.append(`file-${idx}`, fileList[idx], fileList[idx].name);
    }  

    // 👇 Uploading the file using the fetch API to the server ArchivosClasifMotivos?Pais=CL&Anno=2024&Mes=05&Centro=3010&Almacen=3011&Usuario=CROJAS
    const recurso = `${env.REACT_APP_API_URL}/api/ArchivosClasifMotivos?Pais=CL&Anno=${props.data.Anno}&Mes=${props.data.Mes}&Centro=${props.data.Centro}&Almacen=${props.data.Almacen}&Usuario=${emp.user}`; 
    const response = await fetch(recurso, {
                                  method: 'POST',
                                  body: data,
                                  headers: {
                                    'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                                    'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`         
                                  },
                                });
    const res = await response.json();
    setSHCarga(false);
    let msg = "";
    if (response.status >= 400){
      msg = res.Message;  
    }else{
      msg = "Archivo cargado con éxito.";  
    }
    setMsgApi(msg);    
    setShowMsgApiError(true);

    ListadoArchivos();

    const form = document.getElementById("sendFile") as HTMLFormElement | null ;
    form?.reset();
    setFilename("");
    setFileList(null);

  };

  
  useEffect(()=>{

    ListadoArchivos();

  }, [props.Show]);

  return(
      <>
        <Modal show={props.Show} size="xl" >
          <Modal.Header >
            <Modal.Title>{props.Title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div>Adjuntar archivo</div>
                <div className='mb-3 mt-2'>
                  <form id="sendFile" className='row'>
                      <div className='col-lg-10 col-md-12'>
                        <input
                            type="file"
                            id="filename"
                            onChange={(e: any) => {
                              handleFileChange(e);
                            }}
                            className="form-control"
                            accept="*.*"
                        />
                      </div>
                      <div className='col-lg-2 col-md-12'>
                        <Button onClick={handleUploadClick} className="btn btn-success" disabled={!fileList ? true: false}>
                          Enviar
                        </Button>
                      </div>

                  </form>
                </div>
              <br />
              <div className="row d-flex flex-row align-middle mt-2 mb-5">
                  <DataTable
                      columns={columns}
                      data={data}                
                      selectableRowsHighlight
                      fixedHeader
                      pagination
                      highlightOnHover
                      fixedHeaderScrollHeight="600px"
                      paginationComponentOptions={pagOptions}
                  />                                                       
              </div>
          </Modal.Body>
          <Modal.Footer>      
            <Button onClick={()=>handleClose()} className="btn btn-secondary">
              {props.BtnNokName}
            </Button>
          </Modal.Footer>
        </Modal>
        {showCuadroDialogo && <MsgDialog 
            Title="Clasificación Diferencias"
            Icon="!"
            Show={showCuadroDialogo}
            BtnNokName="No, cancelar"
            BtnOkName="Si, continuar"
            Message="Se van a eliminar el documento seleccionado, ¿Está usted seguro de continuar?"
            HandlerClickOk={OnEliminar}
            HanlerdClickNok={()=>setShowCuadroDialogo(false)}
        /> } 
        {showMsgApiError && <MsgDialog3 
            Title="Clasificación Diferencias"
            Icon="!"
            Show={showMsgApiError}
            BtnNokName=""
            BtnOkName="Cerrar"
            Message={msgApi}
            HandlerClickOk={()=>setShowMsgApiError(false)}
            HanlerdClickNok={null}
        />} 
        {sHCarga && <LocalMsgModalSpinner 
            Show={sHCarga}
            text="Un momento por favor, procesando..."
            color="#FF7588"
        />}                      
      </>
  )

  }

  export default SubirArchivoNube;

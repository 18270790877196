
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import * as env from '../../../../env';
import { httpApiGetText } from '../../../../lib';
import { exportToExcel } from '../../../../util';

const MsgModalDetalleMuestras = (
            props: {Show: boolean, BtnOkName: string, data:any, formData: any;
            HandlerClickOk: any}) =>{

  let [records, setRecords] = useState([]);
  const [cpRecords, setCpRecords] = useState([]);  
  const [sHCarga, setSHCarga] = useState(false);  

  let columns = [
    { name: "Cantidad", selector: (row:any) => row.Cantidad, format: (row:any) => row.Cantidad.toLocaleString(), sortable: true},
    { name: "Serial", selector: (row:any) => row.Serial, sortable: true, width:"150px"},
    { name: "Esp", selector: (row:any) => row.Especial, sortable: true},
    { name: "Nro. docto.", selector: (row:any) => row.NroDocto,  sortable: true, width:"150px"},
    { name: "Pos. Docto.", selector: (row:any) => row.PosDocto, width:"150px", sortable: true}, 
    { name: "Fecha", selector: (row:any) => row.Fecha, sortable: true, width:"150px"},
    { name: "Usuario", selector: (row:any) => row.Usuario, sortable: true, width:"150px"},
    { name: "Observación", selector: (row:any) => row.Observacion, width:"250px", wrap:true},        
  ];

  const pagOptions = {
      rowsPerPageText: "Filas por páginas",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos"
  }; 

  const changeTextFiltro = (evnt: any) => {
    if (evnt.target.value.trim() === ""){
        setRecords(cpRecords);  
    }else{
        let reg = cpRecords.filter((dato: any)=> {
            return (dato.Cantidad && dato.Cantidad.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Serial && dato.Serial.includes(evnt.target.value.toUpperCase())) 
                || (dato.Especial && dato.Especial.includes(evnt.target.value.toUpperCase()))  
                || (dato.NroDocto && dato.NroDocto.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.PosDocto && dato.PosDocto.toString().includes(evnt.target.value.toUpperCase()))                     
                || (dato.Fecha && dato.Fecha.includes(evnt.target.value.toUpperCase())) 
                || (dato.Usuario && dato.Usuario.includes(evnt.target.value.toUpperCase())) 
                || (dato.Observacion && dato.Observacion.includes(evnt.target.value.toUpperCase()));             
        });
        setRecords(reg); 
    }
}   

  useEffect(()=>{

    const llamadaApi = async () =>{
      setSHCarga(true);
      const recurso = `${env.REACT_APP_API_URL}/api/MuestraInventario?pais=CL&Anno=${props.formData.Anno}&Mes=${props.formData.Mes}` + 
                      `&Centro=${props.formData.Centro}&Almacen=${props.formData.Almacen}&Muestra_Ref=${props.data.Muestra.split("-")[0].trim().split('*')[1]}&Codigo=${props.data.Codigo}`;  
      const response = await httpApiGetText(recurso, {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
      });
      const arrDta = await JSON.parse(response.message); 
      if (response.code >= 400) {

      }else{
        const auxDta:any = [];
        arrDta.map((obj: any, index: number)=>{
          const newObj = {
            Cantidad: obj.Cantidad, 
            Serial : obj.Serial, 
            Especial : ((obj.Especial)?obj.Especial: ""),
            NroDocto: obj.Nro_Documento,
            PosDocto: (obj.Pos_Docto_Com ? obj.Pos_Docto_Com:""),
            Fecha: obj.Fecha,
            Usuario: obj.Usuario,
            Observacion: (obj.Observacion ? obj.Observacion:"")
          };
          auxDta.push(newObj);
        });
        setRecords(auxDta);       
      }
      setSHCarga(false);
    };

    if (props.Show){
      llamadaApi();
    }

    return ()=> {
        records = [];
    } 
  }, [props.Show]);

  return(
      <>
        <Modal show={props.Show} size="xl" centered>
          <Modal.Header >
            <Modal.Title>{`${props.data.Codigo} - ${props.data.Descripcion}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className='mt-3'>
                  <div>
                      <Button className="btn btn-success" onClick={()=>{ 
                              if (records.length > 0 )
                                exportToExcel("tablaDetalleMuestra", records)
                              }
                          }>
                            Exportar
                        </Button>
                        <div className="d-flex flex-column col col-lg-6 mb-3 ">
                            <label className="m-1">Filtro</label>
                            <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                        </div> 
                      <div>
                          <DataTable
                              columns={columns}
                              data={records}
                              selectableRows
                              selectableRowsHighlight
                              fixedHeader
                              pagination
                              highlightOnHover
                              fixedHeaderScrollHeight="600px"
                              paginationComponentOptions={pagOptions}
                          />                      
                      </div>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button  onClick={props.HandlerClickOk} className="btn btn-success">
              {props.BtnOkName}
            </Button>          
          </Modal.Footer>
        </Modal>
        <LocalMsgModalSpinner 
            Show={sHCarga}
            text="Un momento por favor, verificando detalle..."
            color="#FF7588"
        />        
      </>
  )

}

export default MsgModalDetalleMuestras;


import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

function SelectAlmacenes(props?: any) {

  const emp: any = useSelector((state: any) => state.emp);
  const [valor, setValor] = useState("");
  const [filtro, setFiltro] = useState([""]);

  useEffect(()=>{

    const filtros = () => {

      if (props.centro){
         const cnt = props.centro.split("-")[0].trim();   
         let faux: any[] = emp.almacenes.filter((centro: any)=>{
            return centro.substring(0, 4) === cnt.substring(0, 4);
          }); 
         let auxFaux: any[] = [];
         faux.map((cad: string)=>{
            auxFaux.push(cad.split(" - ")[1] + " - " + cad.split(" - ")[2])
         });
         setFiltro([...auxFaux]);     
         if (props.updateAlmacen){
            props.updateAlmacen(faux[0].split(" - ")[1])
         }
      }
    }

    filtros();
    setValor(props.default);

  },[props.centro, props.almacen]);

  const handler = (event: any) =>{
    setValor(event.target.value);
    props.OnSeleccion(event);
  }   

    return (
      <Form.Select  value={valor} aria-label="Default select example" onChange={(e) => handler(e)}  id={props.id}>
        <option key={-1} value={""}>Seleccione una opción</option>
        {
            (filtro.length > 0) 
              ? filtro.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
              : null
        }
      </Form.Select>
    );
} 

export default SelectAlmacenes;


import { useEffect, useState } from "react";
import { Accordion, Button, Container} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaCalendarAlt, FaTruck, FaUpload} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import BarraMenuHz from "../../../components/BarraMenoHz";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import MsgDialog from "../../../components/MsgDialog";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText } from "../../../lib";
import { SetDataTable, SetEntornoEmp } from "../../../redux/store/Actions";
import ModalCargaMasiva from "./ModalCargaMasiva";
import MsgDialogEspecial from "../../../components/MsgDialogEspecial";

let nr = 0; 

const AperturaReconteoPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [records, setRecords] = useState([]);
    const [cpRecords, setCpRecords] = useState([]);    
    const [showCargaMasiva, setShowCargaMasiva] = useState(false);
    const [sHCarga, setSHCarga] = useState(false);
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true);
    const [msgAbrirPeriodoReconteo, setMsgAbrirPeriodoReconteo] = useState(false);    
    const [hbltCargaMasiva, setHbltCargaMasiva] = useState(true);
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: ""}); 
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    const [msgRespuestInv, setMsgRespuestInv] = useState("");
    const [msgDlgShowConfirm, setMsgDlgShowConfirm] = useState(false);    
    let dt: any = useSelector((state: any) => state.datatable);
    let [rowsPreselected, setRowsPreselected] = useState(new Array<any>()); 
    let [chks, setChks] = useState([]); 
    const [clrRows, setCLRows] = useState(false); 
    const dispatch = useDispatch();   

    let columns = [
        { name: "Código", selector: (row:any) => row.Codigo, sortable: true},
        { name: "E", selector: (row:any) => row.E, sortable: true},
        { name: "Descripción", selector: (row:any) => row.Descripcion, width:"250px", sortable: true, wrap:true},
        { name: "Unidad", selector: (row:any) => row.Un,  sortable: true, right:true},
        { name: "Precio", selector: (row:any) => row.Precio,  format: (row:any) => row.Precio.toLocaleString(), right:true, sortable: true, width:"150px"}, 
        { name: "Sistema", selector: (row:any) => row.Sistema,  format: (row:any) => row.Sistema.toLocaleString(),  sortable: true, right:true, width:"150px"},
        { name: "CC Sistema", selector: (row:any) => row.CCSistema, format: (row:any) => row.CCSistema.toLocaleString(), sortable: true, right:true, width:"150px"},
        { name: "Bloqueado", selector: (row:any) => row.Bloqueado, format: (row:any) => row.Bloqueado.toLocaleString(), right:true, sortable: true, width:"150px"},
        { name: "Valor sistema", selector: (row:any) => row.ValorSistema,  format: (row:any) => row.ValorSistema.toLocaleString(), sortable: true, right:true, width:"150px"},
        { name: "Físico", selector: (row:any) => row.Fisico, format: (row:any) => row.Fisico.toLocaleString(),  sortable: true, right:true, width:"150px"},
        { name: "Valor Físico", selector: (row:any) => row.ValorFisico, format: (row:any) => row.ValorFisico.toLocaleString(),  right:true, sortable: true, width:"150px"},
        { name: "Diferencia", selector: (row:any) => row.Diferencia, format: (row:any) => row.Diferencia.toLocaleString(),  sortable: true, right: true, width:"150px"},
        { name: "Valor Diferencia", selector: (row:any) => row.ValorDiferencia, format: (row:any) => row.ValorDiferencia.toLocaleString(),  sortable: true, right: true, width:"150px"},         
    ]

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  

    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.Codigo && dato.Codigo.includes(evnt.target.value.toUpperCase()))
                    || (dato.E && dato.E.includes(evnt.target.value.toUpperCase())) 
                    || (dato.Descripcion && dato.Descripcion.toString().includes(evnt.target.value.toUpperCase()))  
                    || (dato.Un && dato.Un.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Precio && dato.Precio.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Sistema && dato.Sistema.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.CCSistema && dato.CCSistema.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Bloqueado && dato.Bloqueado.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.ValorSistema && dato.ValorSistema.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Fisico && dato.Fisico.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.ValorFisico && dato.ValorFisico.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Diferencia && dato.Diferencia.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.ValorDiferencia && dato.ValorDiferencia.toString().includes(evnt.target.value.toUpperCase()));                     
            }); 
            setRecords(reg); 
        }
    }  

    const hndlrPeriodo = async (evnt: any) =>{

        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                        
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnFiltrat();             
    }

    const hndlrBtnBuscar = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/CodigosReconteo?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);
        const auxDta:any = [];
        if (response.code >= 400){
            setMsgRespuestInv(arrDta.Message);
            setMsgDlgShowApiResponse(true);
            setHbltCargaMasiva(true);  
            dt = [...auxDta];
            dispatch(SetDataTable(dt));
            setRecords(auxDta);              
        }
        else{
            if (arrDta.Detalle.length > 0){  
                arrDta.Detalle.map((valor: any, index: number)=>{
                    const newObj = {
                        ...valor,
                        E: (valor.E) ? valor.E : ""
                    }
                    auxDta.push(newObj);
                }); 
                //********Pasa el objeto a arreglo para recorrido en table
                dt = [...auxDta];
                dispatch(SetDataTable(dt));
                setRecords(auxDta); 
                setCpRecords(auxDta);
                setHbltCargaMasiva(false);    
            }else{
                setHbltCargaMasiva(true);  
            }       
        }
    }

    const ValidaBtnFiltrat = () => {
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "")){
                setHbltBtnAbrir(false);
        }else{
                setHbltBtnAbrir(true);
        }
    }    

    const hndlrOpcionCentro = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData); 
        
        emp = {...emp, centroDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp})); 

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnFiltrat();   
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData); 
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnFiltrat();                  
    }
    
    const MsgDlghandlerResponseApi = () =>{
        setMsgDlgShowApiResponse(false);
    }  

    const AperturaPeriodos = async ()=>{
        setMsgDlgShowConfirm(false);
        // se hace le proceso de llamado a la Api
        interface tipoObjItem  {Pais: string, Anno:string, Mes:string, Centro: string, Almacen:string, Inv_item_id:string, Especial: string}
        const arrReconteo: tipoObjItem[] = [];
        setSHCarga(true);
        chks.map((code:any)=>{
            const reconteoObj: tipoObjItem = {
                Pais : "CL",
                Anno : formData.Anno,
                Mes : formData.Mes,
                Centro : formData.Centro,
                Almacen: formData.Almacen,
                Inv_item_id : code.Codigo,
                Especial: code.E
            };
            arrReconteo.push(reconteoObj);
        });
        const postReconteo: any = {
            Pais : "CL",
            Anno : formData.Anno,
            Mes : formData.Mes,
            Centro : formData.Centro,
            Almacen: formData.Almacen,
            Usuario : emp.user,
            RConsolidadoReconteo: arrReconteo
        };
        const recurso = `${env.REACT_APP_API_URL}/api/Reconteos`;     
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, postReconteo);  
        const arrDta = await JSON.parse(response.message);     

        if (response.code >= 400){
            setMsgRespuestInv(`${arrDta.Mensaje}`) 
        }else{
            setMsgRespuestInv(`Código de Reconteo : ${arrDta.CodReconteo}. ${arrDta.Mensaje}`)                
        }
        setMsgDlgShowApiResponse(true);
        hndlrBtnBuscar();
        setSHCarga(false); 
        handleClearRows(); 
        setChks([]);      
    }

    const abrerPeridosModalConfirm = (nany?: any) => {
        if (chks.length > 0){
            setMsgDlgShowConfirm(true);
        }else{
            setMsgAbrirPeriodoReconteo(true);
        }
    }

    const preselectedCodeSi = (code: any) =>{
        setRowsPreselected(code); 
    }

    const onRowSelected = (arr: any) =>{
        setChks(arr.selectedRows);
    }

    const criteriPreselectable = (rws: any) => {
        nr++;
        if (rowsPreselected.length > 0){ 
            let stdo = (rowsPreselected.indexOf(rws.Codigo) !== -1);
            if (nr >= records.length){
                nr = 0;
                setRowsPreselected([]);
            }                      
            return stdo;
        }else{
            return (chks.filter((obj: any)=>obj.Codigo === rws.Codigo).length > 0);
        }
    } 

    const handleClearRows = () => {
        setCLRows(!clrRows);
    } 

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            } 
            
            ValidaBtnFiltrat(); 
        }

        preFiltros();  

    }, []);   

    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Reconteo / Apertura de Reconteo
                </div>
                <div className="h4 mt-4 mb-4 ms-3">APERTURA DE RECONTEO</div>
            </div> 
            <Container fluid>
                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS RECONTEO</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column w-50">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2"
                                    onChange={hndlrPeriodo}
                                    value={`${formData.Anno}-${formData.Mes}`} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault}/></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}  default={emp.almacenDefault} /></div>
                                </Container>
                            </div>

                            <div className="text-end">
                                <Button type="button" className=" btn border border-0" 
                                    disabled={hbltBtnAbrir}  onClick={hndlrBtnBuscar} style={{backgroundColor: "#00B5B8"}}
                                >
                                    Filtrar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>         

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">RECONTEO</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="text-start mb-4">
                                    <Button type="button"  className="btn-lg me-2 border-0" style={{backgroundColor:"#16D39A"}}>
                                        <label htmlFor="" className="" onClick={()=>abrerPeridosModalConfirm()}>Abrir</label>
                                    </Button>                                
                                    <Button type="button" className="ms-1 border-0" style={{backgroundColor:"#2DCEE3"}}
                                        disabled={hbltCargaMasiva}
                                        onClick={()=>setShowCargaMasiva(true)}
                                    >
                                        <FaUpload className=" m-2"/>Carga masiva
                                    </Button>
                                    <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                        <label className="m-1">Filtro</label>
                                        <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                    </div>                                        
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={records}
                                    selectableRows
                                    selectableRowSelected={(rowsPreselected.length > 0) ? criteriPreselectable: null}                                    
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                    onSelectedRowsChange={onRowSelected}
                                    clearSelectedRows={clrRows}                                    
                                />    

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
            </Container>
            <ModalCargaMasiva 
                Show={showCargaMasiva}
                Title="Carga masiva"
                BtnOkName="Cargar archivo"
                BtnNokName="Cerrar"
                HandlerClickOk={()=>alert("Ok")}
                HanlerdClickNok={()=>setShowCargaMasiva(false)}
                HandlerPreselected={preselectedCodeSi}
            />
            {/*********** cuadro de dialogo para msj de api **********/}
            <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Reconteo`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApi}
                HanlerdClickNok={null}
            />              

{/*             <MsgModalDialogEspecial
                Show={msgNoSelection}
                Title={`Reconteo`}
                Icon="x"
                Message="Para Reconteo debe seleccionar al menos un dato."
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={()=>setMsgNoSelection(false)}
                HanlerdClickNok={null}
            />  */}

            <MsgDialogEspecial
                    Show={msgDlgShowConfirm}
                    Title="Reconteo"
                    Icon="!"
                    Message={"¿Esta seguro? La información se envíará para reconteo."}
                    BtnOkName={"Ok"}
                    BtnNokName="Cancelar"
                    HandlerClickOk={AperturaPeriodos}
                    HanlerdClickNok={()=>setMsgDlgShowConfirm(false)}
                    body={null}
            />             
            
            <MsgDialog
                Show={msgAbrirPeriodoReconteo}
                Title='Apertura de Reconteo'
                BtnOkName='Cerrar'
                HandlerClickOk={()=>setMsgAbrirPeriodoReconteo(false)}
                Message="Para abrir períodos, debe seleccionar al menos un código de producto"
                Icon='x'
                BtnNokName=''
                HanlerdClickNok={()=>setMsgAbrirPeriodoReconteo(false)}
            />  

            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento porfavor, procesando..."
                color="#FF7588"
            />              
        </Container>
    );
}

export default AperturaReconteoPage;

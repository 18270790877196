import { useEffect, useState } from "react";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { FaCalendarAlt, FaTruck, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import { exportToExcel } from "../../../util";
import ModalCargaMasivaMuestras from "./ModalCargaMasivaMuestras";
import ModalAbrirPeriodo from "./ModalAbrirPeriodo";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
  
let nr = 0;  
let aarrSi:any = [];

const PeriodoMuestrasPageII = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: ""});
    const [sHCarga, setSHCarga] = useState(false);
    const [negocio, setNegocio] = useState(new Array());    
    let [familia, setFamilia] = useState(new Array());        
    let [modelo, setModelo] = useState(new Array());   
    let [records, setRecords] = useState(new Array<any>());  
    const [cpRecords, setCpRecords] = useState(new Array<any>());    
    const [filtrobusqueda, setFiltroBusqueda] = useState(new Array<any>());     
    let [formSelect, setFormSelect] = useState({ Negocio: "TODOS", Modelo: "TODOS", Familia: "TODOS"}); 
    const [showCargaMasiva, setShowCargaMasiva] = useState(false); 
    const [showAbrirPeriodo, setShowAbrirPeriodo] = useState(false);
    const [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    const [MsgApiResponse, setMsgApiResponse] = useState(""); 
    const [msgAdvertencia, setMsgAdvertencia] = useState(false);         
    let [chks, setChks] = useState([]);   
    let [rowsPreselected, setRowsPreselected] = useState(new Array<any>());   
    const [clrRows, setCLRows] = useState(false);  
    const dispatch = useDispatch();               
         

    let columns = [
        { name: "Código", selector: (row:any) => row.Codigo, sortable: true},
        { name: "Especial", selector: (row:any) => row.Especial, sortable: true},
        { name: "Descripción", selector: (row:any) => row.Descripcion, sortable: true, width:"250px", wrap:true},
        { name: "UM", selector: (row:any) => row.Unidad_medida, sortable: true},
        { name: "Saldo SAP", selector: (row:any) => row.Saldo_sap,  format: (row:any) => row.Saldo_sap.toLocaleString(), right:true, sortable: true, width:"150px"},            
        { name: "Saldo Ctrl. Cal.", selector: (row:any) => row.Control_cal_sap,   format: (row:any) => row.Control_cal_sap.toLocaleString(), sortable: true, right:true, width:"150px"},
        { name: "Bloqueado", selector: (row:any) => row.Bloqueado, format: (row:any) => row.Bloqueado.toLocaleString(), sortable: true, width:"150px", right:true},
        { name: "Total Sap", selector: (row:any) => row.Total_sap, format: (row:any) => row.Total_sap.toLocaleString(), right:true, sortable: true, width:"150px"},           
        { name: "Negocio", selector: (row:any) => row.Negocio,  sortable: true, width:"150px"},
        { name: "Familia", selector: (row:any) => row.Familia, sortable: true, width:"150px"},
        { name: "Modelo", selector: (row:any) => row.Modelo, sortable: true, width:"150px"},  
        { name: "Det. Especial", selector: (row:any) => row.Det_Especial,  omit: true, sortable: true, width:"150px"},                           
    ]

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  

    const concatDistinct = (arrIni: any, arrFin: any) =>{
            
        const arrRes = [...arrIni];

        arrFin.map((dt: any)=>{
            if (arrRes.findIndex((valor:any) => valor === dt) === -1){
                arrRes.push(dt);
            }
        });

        return (arrRes);
    }

    const ValidaBtnAbrir = () => {     
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "")){     
                setHbltBtnAbrir(false); 
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();                    
    }

    const hndlrOpcionCentro = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);  
        
        emp = {...emp, centroDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp})); 

        sessionStorage.setItem("entorno", JSON.stringify(emp));     

        ValidaBtnAbrir();   
         
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnAbrir();                               
    }   
    
    const btnBuscar = async () =>{
        setSHCarga(true);

        aarrSi = [];
        setRowsPreselected([]);
        setChks([]);

        const recurso = `${env.REACT_APP_API_URL}/api/MuestraInventario`;     
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, {...formData});
        const arrDta = await JSON.parse(response.message); 
        setSHCarga(false);
        if(response.code >= 400){
            console.log("Error Muestras - 400:", response, recurso);
        }else{     
            const auxDta:any = [];          
            arrDta.map((obj: any, index: number)=>{
                const newObj = {
                    ...obj,
                    Especial: (obj.Especial !== null) ? obj.Especial : "",
                    Negocio: obj.Negocio.trim(),
                    Familia: obj.Familia.trim(),
                    Modelo: obj.Modelo.trim()
                };
                if (negocio.findIndex((dato: string)=>dato === obj.Negocio) === -1){
                    negocio.push(obj.Negocio.trim());
                }
                if (familia.findIndex((dato: string)=>dato === obj.Familia) === -1){
                    familia.push(obj.Familia.trim());
                }          
                if (modelo.findIndex((dato: string)=>dato === obj.Modelo) === -1){
                    modelo.push(obj.Modelo.trim());
                }                         
                auxDta.push(newObj);  
            });
            negocio.sort();
            negocio.unshift("TODOS"); 

            familia.sort();
            familia.unshift("TODOS");    

            modelo.sort();
            modelo.unshift("TODOS");                
    
            setRecords([...auxDta]);
            setCpRecords([...auxDta]);
            setFiltroBusqueda([...auxDta])
        }       
    }

    const FiltrarTabla = (slt: any) =>{        
        let arr = []; 
        if (slt.id === "Negocio"){
            if (slt.value === "TODOS"){
                arr = [...cpRecords]; 
            }else{
                arr = cpRecords.filter((obj: any)=> obj.Negocio === slt.value);                
            }
            // Selecciona las opciones que deben aparecer en el selector de familia que solo estén en el filtro de negocios
            familia =[];
            arr.map((obj: any)=>{
                if (familia.findIndex((dato: string)=> dato === obj.Familia) === -1){
                    familia.push(obj.Familia.trim());
                } 
            });
            familia.sort();
            familia.unshift("TODOS");
            setFamilia([...familia]);                        
        }else if (slt.id === "Familia"){
            if (slt.value === "TODOS"){
                if (formSelect.Negocio === "TODOS"){
                    arr = [...cpRecords]; 
                }else{
                    arr = cpRecords.filter((obj: any)=> obj.Negocio === formSelect.Negocio);                   
                }
            }else{
                if (formSelect.Negocio === "TODOS"){
                    arr = cpRecords.filter((obj: any)=> (obj.Familia === slt.value));
                }else{
                    arr = cpRecords.filter((obj: any)=> (obj.Negocio === formSelect.Negocio) && (obj.Familia === slt.value));                   
                }
            } 
            // Selecciona las opciones que deben aparecer en el selector de modelo que sólo estén en el filtro de familia            
            modelo = [];
            arr.map((obj: any)=>{
                if (modelo.findIndex((dato: string)=> dato === obj.Modelo) === -1){
                    modelo.push(obj.Modelo.trim());
                } 
            });
            modelo.sort();
            modelo.unshift("TODOS");
            setModelo([...modelo]);           
        }else{
            if((formSelect.Negocio === "TODOS") && (formSelect.Familia === "TODOS")){
                arr = [...cpRecords];
            }else if ((formSelect.Negocio !== "TODOS") && (formSelect.Familia === "TODOS")){
                arr = cpRecords.filter((obj: any)=> ((obj.Negocio === formSelect.Negocio)));
            }else if ((formSelect.Negocio === "TODOS") && (formSelect.Familia !== "TODOS")){
                arr = cpRecords.filter((obj: any)=> ((obj.Familia === formSelect.Familia)));               
            }else if ((formSelect.Negocio !== "TODOS") && (formSelect.Familia !== "TODOS")){
                arr = cpRecords.filter((obj: any)=> ((obj.Negocio === formSelect.Negocio) && (obj.Familia === formSelect.Familia)));         
            }

            if (slt.value !== "TODOS"){
                arr = arr.filter((obj: any)=> ( (obj.Modelo === slt.value)) );
            }
        }
        setRecords([...arr]);    
        setFiltroBusqueda([...arr]);
    }    

    const handlerSelect = (evnt: any) =>{
        if (evnt.target.id === "Negocio"){
            formSelect = {Negocio: evnt.target.value.trim(), Modelo: "TODOS", Familia: "TODOS"};            
        }else if (evnt.target.id === "Familia"){
            formSelect = {...formSelect, Familia: evnt.target.value.trim(), Modelo: "TODOS"};     
        }else{
            formSelect = {...formSelect, Modelo: evnt.target.value.trim()};                
        }
        setFormSelect({...formSelect}); 
        FiltrarTabla(evnt.target);   
        preselectedCodeSi(aarrSi);
    }

    const changeTextFiltro = (evnt: any) => {
        records = [...filtrobusqueda];
        let reg = records.filter((dato: any)=> {
            return (dato.Codigo && dato.Codigo.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Especial && dato.Especial.includes(evnt.target.value.toUpperCase())) 
                || (dato.Descripcion && dato.Descripcion.includes(evnt.target.value.toUpperCase()))  
                || (dato.Unidad_medida && dato.Unidad_medida.includes(evnt.target.value.toUpperCase())) 
                || (dato.Saldo_sap && dato.Saldo_sap.toString().includes(evnt.target.value.toUpperCase()))                     
                || (dato.Control_cal_sap && dato.Control_cal_sap.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Bloqueado && dato.Bloqueado.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Total_sap && dato.Total_sap.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Negocio && dato.Negocio.includes(evnt.target.value.toUpperCase()))
                || (dato.Familia && dato.Familia.includes(evnt.target.value.toUpperCase()))
                || (dato.Modelo && dato.Modelo.includes(evnt.target.value.toUpperCase()));                
        });
        setRecords([...reg]); 
    }  
    
    const procesarApertura = async (val: any) =>{       

        /**************************************
         * Proceso que extrae las filas seleccionadas para abrir período 
         */
        const rows: any = [];
        aarrSi.map((cod: string)=>{
            const fltr = cpRecords.filter((rw: any)=> rw.Codigo === cod);
            if (fltr.length > 0){
                rows.push(fltr[0]);
            }
        });

        setShowAbrirPeriodo(false);
        let detalle: any[] = [];
        rows.map((obj: any)=>{
            const { chk, icon, ...objBdy } = obj;
            detalle.push(objBdy);
        }); 

        const body = {
            ...formData,
            Usuario: emp.user,
            Observacion:val,
            Detalle: detalle
        };
        const recurso = `${env.REACT_APP_API_URL}/api/MuestraInventarioAbrir`;     
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, body);
        const arrDta = await JSON.parse(response.message);        
        if (response.code >= 400){
            setMsgApiResponse(arrDta);
        }else{
            setMsgApiResponse(`Operación exitosa. Referencia : ${arrDta.Muestra_Referencia}`);
        }
        setShowMsgApiResponse(true);
        handleClearRows();
        setChks([]);
        aarrSi = [];
        
    }

    const preselectedCodeSi = (code: any) =>{

        setRowsPreselected(code);  
    }   

    const onRowSelected = (arr: any) =>{

        const auxarr: any = [];
        arr.selectedRows.map((dt: any)=>{
            auxarr.push(dt.Codigo);
        });
        aarrSi = concatDistinct(aarrSi, auxarr);      

        if ((arr.allSelected)){
            if (rowsPreselected.length > 0){
                preselectedCodeSi(aarrSi);
                rowsPreselected = [];
            }
        }else{
            if ((arr.selectedCount === 0) && (formSelect.Negocio === "TODOS")){
                aarrSi = [];
                preselectedCodeSi([]);
            }
        } 
        setChks(aarrSi);
    }

    const criteriPreselectable = (rws: any) => {
        nr++;
        if (rowsPreselected.length > 0){ 
            let stdo = (rowsPreselected.indexOf(rws.Codigo) !== -1);
            if (nr >= records.length){
                nr = 0;
                setRowsPreselected([]);
            }                      
            return stdo;
        }else{
            return (chks.filter((obj: any)=>obj.Codigo === rws.Codigo).length > 0);
        }
    }  

    const handleClearRows = () => {
        setCLRows(!clrRows);
    } 

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            ValidaBtnAbrir(); 
        }

        preFiltros();      

    }, []);    
    
    useEffect(()=>{

       aarrSi = concatDistinct(aarrSi, rowsPreselected);
       setRowsPreselected([...aarrSi]);

    }, [showCargaMasiva]);     
  
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Muestras / Período de Muestras
                </div>
                <div className="h4 mt-4 mb-4 ms-3">PERÍODO DE MUESTRAS</div>
            </div> 
            <Container fluid>  

                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS MUESTRAS</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>
                            </div>

                            <div className=" col-md-12 col-lg-12 text-end">
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                    disabled={hbltBtnAbrir} onClick={() => btnBuscar()}
                                >
                                    Buscar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">MUESTRAS</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-4" >
                                    <label className="form-label">Seleccionar Negocio</label>    
                                    <div>
                                        <Form.Select  value={formSelect.Negocio} aria-label="Default select example" onChange={(e) => handlerSelect(e)}  id="Negocio">
                                            {
                                                negocio.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                            }                                          
                                        </Form.Select>
                                    </div>
                                </Container>

                                <Container fluid className="mb-3  col-md-12 col-lg-4" >
                                    <label className="form-label">Seleccionar Familia </label>    
                                    <div>
                                        <Form.Select  value={formSelect.Familia} aria-label="Default select example" onChange={(e) => handlerSelect(e)}  id="Familia">
                                            {
                                                familia.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                            }
                                        </Form.Select>
                                    </div>
                                </Container>

                                <Container fluid className="mb-3  col-md-12 col-lg-4" >
                                    <label className="form-label">Seleccionar Modelo</label>    
                                    <div>
                                        <Form.Select  value={formSelect.Modelo} aria-label="Default select example" onChange={(e) => handlerSelect(e)}  id="Modelo">
                                            {
                                                modelo.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                            }
                                        </Form.Select>
                                    </div>
                                </Container> 
                            </div> 
                            <div  className="text-start mb-2">
                                <Button type="button"  className="btn-lg me-2 border-0" style={{backgroundColor:"#16D39A"}}
                                    onClick={()=>{

                                        if (chks.length < 1){
                                            setMsgAdvertencia(true);
                                        }else{
                                            setShowAbrirPeriodo(true);
                                        }
                                    }}                
                                >
                                    Abrir
                                </Button>                                
                                <Button type="button" className="ms-1 border-0" style={{backgroundColor:"#2DCEE3"}}
                                    disabled={(records.length <= 0)}
                                    onClick={()=> {setShowCargaMasiva(true)}}     
                                >
                                    <FaUpload className=" m-2"/>Carga masiva
                                </Button>
                            </div>                             
                            <div className="row d-flex flex-row align-middle mt-2 mb-2">
                                <div  className="col-md-12 col-lg-6  d-flex align-items-center" >
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" border-0"
                                        disabled={(records.length <= 0)}
                                        onClick={()=> {exportToExcel("TablaMuestra", records)}}
                                    >
                                        Exportar tabla a Excel
                                    </Button>
                                </div>
                                <div className="d-flex flex-column col col-lg-6 col-md-12 mb-3 ">
                                    <label className="m-1">Filtro</label>
                                    <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                </div>                        
                            </div> 
                            <div className="">
                                <DataTable 
                                    columns={columns}
                                    data={records}
                                    selectableRows    
                                    selectableRowSelected={(rowsPreselected.length > 0) ? criteriPreselectable: null}       
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                    onSelectedRowsChange={onRowSelected}
                                    clearSelectedRows={clrRows}
                                />                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>
            <ModalAbrirPeriodo 
                Show={showAbrirPeriodo}
                Title="Período de Muestras"
                Message={"Se va abrir período de muestras para los elementos seleccionados " +   
                        "Si desea continuar escriba el nombre de la muestra y luego pulse el botón Ok"}
                BtnNokName="Cancelar"
                BtnOkName="Aceptar"
                HandlerClickOk={procesarApertura}
                HanlerdClickNok={()=>setShowAbrirPeriodo(false)}
                Icon="!"
            />
            <MsgDialog 
                Title="Período de Muestras"
                Icon="!"
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerClickOk={()=>setShowMsgApiResponse(false)}
                HanlerdClickNok={null}
            /> 
            <MsgDialog 
                Title="Período de Muestras"
                Icon="!"
                Show={msgAdvertencia}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message="Para abrir un período muestras, debe seleccionar, almenos, un producto."
                HandlerClickOk={()=>setMsgAdvertencia(false)}
                HanlerdClickNok={null}
            /> 
                         
            {/******* Carga masiva *******************************/}
            <ModalCargaMasivaMuestras 
                Data={cpRecords}
                Show={showCargaMasiva}
                Title="Carga masiva"
                BtnOkName="Cargar archivo"
                BtnNokName="Cerrar"
                HandlerClickOk={null}
                HanlerdClickNok={()=>{setShowCargaMasiva(false)}}
                HandlerPreselected={preselectedCodeSi}
            />             

            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />             
        </Container>    
    );
}

export default PeriodoMuestrasPageII;
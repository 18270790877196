import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useEffect, useState } from 'react';
import { httpApiGetText, httpApiPostText } from '../../../../lib';
import * as env from '../../../../env';
import MsgDialog from '../../../../components/MsgDialog';
import DataTable from 'react-data-table-component';
import { FaRegTrashAlt } from 'react-icons/fa';

let columns = [
    { name: "Clasificación", selector: (row:any) => row.DESCRIPCION, sortable: true, width:"200px"},
    { name: "Motivo", selector: (row:any) => row.SUB_DESCRIPCION, sortable: true, width:"200px"},
    { name: "Cantidad", selector: (row:any) => row.DET_CANTIDAD, sortable: true, width:"150px", right:true},                           
    { name: "Acciones", selector: (row:any) => row.Acciones, grow:2},    
];

const MsgDialogDiferencia = (props: {Show: boolean, obj: any,  HandlerClickOk: any}) => {
             
    let [clasificacion, setClasificacion] = useState(new Array());   
    let [subCalsificacion, setsubCalsificacion] = useState(new Array());       
    let [subtipo, setSubTipo] = useState("");       
    let [tipo, setTipo] = useState("");         
    let [cantidad, setCantidad] = useState(0);   
    let [records, setRecords] = useState(new Array<any>());      
    const [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    const [MsgApiResponse, setMsgApiResponse] = useState("");     
    const [showMsgApiError, setShowMsgApiError] = useState(false); 
    const [MsgApiError, setMsgApiError] = useState("");     
    
    let [diferencia, setDiferencia] = useState("");       
    let [clasificar, setClasificar] = useState("");         
    let [clasificado, setClasificado] = useState("");   

    const OnSelectChange = async (evnt: any) =>{

        tipo = evnt.target.value;
        setTipo(tipo);
        // Llamado al servicio que trae los subtipos    
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMotivos/BuscarClasifMotivo?paramClasif=${tipo}`;            
        const response = await httpApiGetText(recurso, {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const dta = await JSON.parse(response.message);
        subCalsificacion = [...dta];
        setsubCalsificacion([...subCalsificacion]);

    }

    const HnadlerSubtipo = (evnt: any) =>{
        subtipo = evnt.target.value;
        setSubTipo(subtipo);
    }

    const HnadlerCantidad = (evnt: any) =>{
        cantidad = evnt.target.value;
        setCantidad(cantidad);
    }   
    
    const mostarTabla = async () =>{
 
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMotivos?PAIS=CL&ANNO=${props.obj.Anno}&MES=${props.obj.Mes}&CENTRO=${props.obj.Centro}&ALMACEN=${props.obj.Almacen}&INV_ITEM_ID=${props.obj.Codigo}&ESPECIAL=`;     
        const response = await httpApiGetText(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });

        const arrDta = await JSON.parse(response.message);    
        if(response.code >= 400){
            setMsgApiError(arrDta.Message);
            setShowMsgApiError(true);
        }else{
            diferencia = arrDta.DIFERENCIA;
            setDiferencia(diferencia);

            clasificar = arrDta.CLASIFICAR;
            setClasificar(clasificar);

            clasificado = arrDta.CLASIFICADO;
            setClasificado(clasificado);

            const auxDta:any = [];
            arrDta.Detalle.map((obj: any)=>{
                const newObj = {
                    DESCRIPCION: obj.DESCRIPCION,
                    SUB_DESCRIPCION: obj.SUB_DESCRIPCION,
                    DET_CANTIDAD: obj.DET_CANTIDAD,
                    Acciones: <div >
                                    <a href="#!" id={`${obj.DET_INV_ITEM_ID}-${1}`} onClick={()=>{
                                            eliminaDiferencia(obj);
                                        }}>
                                        <FaRegTrashAlt title="Eliminar" color={'white'}  className='bg-danger rounded-circle p-1 h4'/>   
                                    </a> 
                              </div>                    
                };
                auxDta.push(newObj);
            });
            records = [...auxDta];
            setRecords([...records]);
        }    
    }       

    const hendlerAdd = async () =>{
        
        const req = {
            DET_PAIS: "CL",
            DET_ANNO: props.obj.Anno,
            DET_MES: props.obj.Mes,
            DET_CENTRO: props.obj.Centro,
            DET_ALMACEN: props.obj.Almacen,
            DET_INV_ITEM_ID: props.obj.Codigo,
            DET_ESPECIAL: props.obj.E,
            DET_SUB_ID_MOTIVO: subtipo,
            DET_ID_CLASIFICACION: tipo,
            DET_CANTIDAD : cantidad               
        };  
        const bdy = [req];
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMotivos`;     
        const response = await httpApiPostText(recurso, "PUT", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        const arrDta = await JSON.parse(response.message);   

        if(response.code >= 400){
            setMsgApiError(arrDta.Message);
            setShowMsgApiError(true);
        }else{
            mostarTabla(); 
        }        
    }      

    const eliminaDiferencia = async(obj: any) => {

        const req = {
            DET_PAIS: "CL",
            DET_ANNO: obj.DET_ANNO,
            DET_MES: obj.DET_MES,
            DET_CENTRO: obj.DET_CENTRO,
            DET_ALMACEN: obj.DET_ALMACEN,
            DET_INV_ITEM_ID: obj.DET_INV_ITEM_ID,
            DET_ESPECIAL: "",
            DET_SUB_ID_MOTIVO: obj.DET_SUB_ID_MOTIVO,
            DET_ID_CLASIFICACION: obj.DET_ID_CLASIFICACION
        };
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMotivos`;        
        const response = await httpApiPostText(recurso, "DELETE", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, req);
        const arrDta = await JSON.parse(response.message);         
        if(response.code >= 400){
            setMsgApiError(arrDta.Message);
            setShowMsgApiError(true);
        }else{

            mostarTabla();
        }
    }    

    useEffect(()=>{

        const init = async () =>{
            const recurso = `${env.REACT_APP_API_URL}/api/ClasifDiferenciasInventario`;     
            const response = await httpApiGetText(recurso,{
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });
            const arrDta = await JSON.parse(response.message); 
            clasificacion = [...arrDta];
            setClasificacion([...clasificacion]);
        }

        init();
        mostarTabla();

    }, []);    

 return (
      <div>
        <Modal show={props.Show} centered={true} size="xl" >
            <Modal.Header  className=' bg-success' >
                <Modal.Title className='h3 text-center text-light'>
                    <h3>Clasificación Diferencia</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className=' row m-3'>
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                <div className=' fw-bolder'>
                                    Código Material
                                </div>
                                <div>
                                    {props.obj.Codigo}
                                </div>                                
                            </div>
                        </div>
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Descripción
                                    </div>
                                    <div>
                                        {props.obj.Descripcion}
                                    </div>                                
                                </div>
                        </div>                        
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Especial
                                    </div>
                                    <div>
                                        {props.obj.E}
                                    </div>                                
                            </div>     
                        </div>
                    </div>  
                    <div className=' row m-3'>
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className='fw-bolder'>
                                        Diferencia
                                    </div>
                                    <div className=''>
                                        {diferencia}
                                    </div>                                
                            </div>  
                        </div>   
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Clasificar
                                    </div>
                                    <div className=''>
                                        {clasificar}
                                    </div>                                
                            </div>  
                        </div>                          
                        <div className='col col-lg-4 col-md-12 '>
                            <div className='d-flex flex-column'>
                                    <div className=' fw-bolder'>
                                        Clasificado
                                    </div>
                                    <div className=''>
                                        {clasificado}
                                    </div>                                
                            </div>  
                        </div>                    
                    </div>                                          
                    <hr />
                    <div className=' row m-3'>
                        <div className='col col-lg-3 col-md-12'>
                            <div>Clasificación</div>
                            <div>
                                <select  value={tipo} id="tipo" className="form-select mt-3 col-md-12 col-lg-6" onChange={OnSelectChange} >
                                    <option key={-3} value={""}>Seleccione tipo</option>                                                                            
                                    {
                                        clasificacion.map((obj: any, idx:number)=> <option key={idx} value={obj.ID}>{obj.DESCRIPCION}</option>)
                                    }
                                </select >
                            </div>
                        </div>
                        <div className='col col-lg-3 col-md-12'>
                            <div>
                                Motívo
                            </div>
                            <div>
                                <select value={subtipo} id="formClasificacion" className="form-select mt-3 col-md-12 col-lg-6" onChange={HnadlerSubtipo} >
                                    <option key={-3} value={""}>Seleccione Subtipo</option>                                                                            
                                    {
                                        subCalsificacion.map((obj: any, idx:number)=> <option key={idx} value={obj.SUB_ID_MOTIVO}>{obj.SUB_DESCRIPCION}</option>)
                                    }
                                </select >
                            </div>                            
                        </div>                        
                        <div className='col col-lg-3 col-md-12'>
                            <div>
                                Cantidad
                            </div>
                            <div>
                                <input type="number" id="cantidad" size={8}  className="form-control mt-3 col-md-12 col-lg-6 text-end"
                                    value={cantidad}
                                    onChange={HnadlerCantidad} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>                            
                        </div>
                        <div className='col col-lg-3 col-md-12 d-flex align-items-end justify-content-center'>
                            <div>
                                <Button className='btn btn-success' onClick={hendlerAdd}
                                    disabled={(tipo === "") || (subtipo === "") || (cantidad === 0)}
                                > + Agrega clasificación
                                </Button>                                
                            </div>
                        </div>   
                    </div>    
                    <hr />   
                    <div>
                        <DataTable
                            columns={columns}
                            data={records}                
                            selectableRowsHighlight
                            fixedHeader
                            pagination
                            highlightOnHover
                        />                            
                    </div>                                 
            </Modal.Body>                
            <Modal.Footer className=''>
                <Button type="button" className="btn btn-success btnColorOk" onClick={()=>props.HandlerClickOk(clasificar, clasificado)}>
                    Cerrar
                </Button>                                 
            </Modal.Footer>
        </Modal>
        {showMsgApiResponse && <MsgDialog 
                Title="Clasificación Diferencias"
                Icon="!"
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerClickOk={()=>setShowMsgApiResponse(false)}
                HanlerdClickNok={null}
            /> } 
            {showMsgApiError && <MsgDialog 
                Title="Clasificación Diferencias"
                Icon="!"
                Show={showMsgApiError}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiError}
                HandlerClickOk={()=>setShowMsgApiError(false)}
                HanlerdClickNok={null}
            />}                   
      </div>
  );
}

export default MsgDialogDiferencia;
import { useEffect, useState } from "react";
import { Accordion, Button, Container} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaCalendarAlt, FaTruck} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import BarraMenuHz from "../../../components/BarraMenoHz";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import * as env from '../../../env';
import { httpApiGetText} from "../../../lib";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import { exportToExcel } from "../../../util";
import Multiselect from "multiselect-react-dropdown";

let nr = 0; 

const KardexFinalPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [records, setRecords] = useState([]);
    const [cpRecords, setCpRecords] = useState([]);    
    const [centros, setCentros] = useState([]);    
    const [almacenesFiltrados, setAlmacenesFiltrados] = useState([]);
    const [almacenesSelected, setAlmacenesSelected] = useState([]);    
    const [btnToExcel, setBtnToExcel] = useState(true);
    const [sHCarga, setSHCarga] = useState(false);
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true);   
    const [periodo, setPeriodo] = useState(true);      
    const [fechaInicial, setFechaInicial] = useState("dd/MM/yyyy");
    const [fechaFinal, setFechaFinal] = useState("dd/MM/yyyy");
    const [fechaMin, setFechaMin] = useState("dd/MM/yyyy");    
    const [tiempoInicial, setTiempoInicial] = useState("");
    const [tiempoFinal, setTiempoFinal] = useState("");   
    const [habilitaFechas, setHabilitaFechas] = useState(true); 
    const [habilitaTiempoFinal, setHabilitaTiempoFinal] = useState(true);        
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: ""}); 
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    const [msgRespuestInv, setMsgRespuestInv] = useState("");
    const dispatch = useDispatch();   

    //*********Configuración de la Grilla */
    let columns = [
        { name: "Centro", selector: (row:any) => row.CENTRO.toLocaleString(), sortable: true, width:"100px"},
        { name: "Almacén", selector: (row:any) => row.ALMACEN.toLocaleString(), sortable: true, width:"100px"},
        { name: "SKU Código", selector: (row:any) => row.INV_ITEM_ID.toLocaleString(), width:"120px", sortable: true},
        { name: "Especial", selector: (row:any) => row.ESPECIAL, width:"90px", sortable: true},
        { name: "Descripción", selector: (row:any) => row.DESCRIPCION, width:"280px", sortable: true, wrap:true},
        { name: "Unidad", selector: (row:any) => row.UNIDAD, width:"90px", sortable: true, wrap:true},   
        { name: "Fecha Apertura", selector: (row:any) => row.FECHA_APERTURA, width:"130px", sortable: true, wrap:true},
        { name: "Hora Apertura", selector: (row:any) => row.HORA_APERTURA, width:"130px", sortable: true, wrap:true},   
        { name: "Fecha Cierre", selector: (row:any) => row.FECHA_CIERRE, width:"130px", sortable: true, wrap:true},
        { name: "Hora Cierre", selector: (row:any) => row.HORA_CIERRE, width:"110px", sortable: true, wrap:true},             
        { name: "Cantidad SAP", selector: (row:any) => row.CANTIDAD_SAP,  format: (row:any) => row.CANTIDAD_SAP.toLocaleString(), right:true, sortable: true, width:"130px"},    
        { name: "Cantidad Conteo", selector: (row:any) => row.CANTIDAD_CONTEO,  format: (row:any) => row.CANTIDAD_CONTEO.toLocaleString(),  right:true, sortable: true, width:"150px"},                 
        { name: "Diferencia", selector: (row:any) => row.DIFERENCIA, format: (row:any) => row.DIFERENCIA.toLocaleString(), right:true, sortable: true, width:"120px"},   
        { name: "Cantidad Ajustada", selector: (row:any) => row.CANTIDAD_AJUSTADA, format: (row:any) => row.CANTIDAD_AJUSTADA.toLocaleString(),  right:true, sortable: true, width:"150px"},                
        { name: "Fecha de Ajuste", selector: (row:any) => row.FECHA_AJUSTE, sortable: true, width:"150px", wrap:true}, 
        { name: "Cantidad Inventario", selector: (row:any) => row.CANTIDAD_INVENTARIO, format: (row:any) => row.CANTIDAD_INVENTARIO.toLocaleString(),  sortable: true, right:true, width:"160px", wrap:true},
        { name: "Valor Inventario", selector: (row:any) => row.VALOR_INVENTARIO, format: (row:any) => row.VALOR_INVENTARIO.toLocaleString(),  sortable: true, right:true, width:"150px"},
        { name: "Fecha Bajada saldos", selector: (row:any) => row.FECHA_BAJADA_SALDOS, sortable: true, right:true, width:"160px", wrap:true},
        { name: "Hora Bajada saldos", selector: (row:any) => row.HORA_BAJADA_SALDOS, sortable: true, right:true, width:"160px"},                 
    ]

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  

    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.CENTRO && dato.CENTRO.includes(evnt.target.value.toUpperCase()))
                    || (dato.ALMACEN && dato.ALMACEN.includes(evnt.target.value.toUpperCase())) 
                    || (dato.INV_ITEM_ID && dato.INV_ITEM_ID.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.ESPECIAL && dato.ESPECIAL.includes(evnt.target.value.toUpperCase())) 
                    || (dato.DESCRIPCION && dato.DESCRIPCION.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.UN && dato.UN.toString().includes(evnt.target.value.toUpperCase()))                     
                    || (dato.CANTIDAD_SAP && dato.CANTIDAD_SAP.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.CANTIDAD_CONTEO && dato.CANTIDAD_CONTEO.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.CANTIDAD_INVENTARIO && dato.CANTIDAD_INVENTARIO.toString().includes(evnt.target.value.toUpperCase()))                     
                    || (dato.DIFERENCIA && dato.DIFERENCIA.toString().includes(evnt.target.value.toUpperCase()));                     
            }); 
            setRecords(reg); 
        }
    }  
    //**********fin configuración de grillas************ */

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                        
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);   

        if (!mm)
            setHabilitaFechas(true);
        else
            setHabilitaFechas(false); 

        setFechaInicial(`${yy}-${mm}-01`);
        setFechaFinal(`${yy}-${mm}-01`);
        
        setPeriodo(false);
    }

    const hndlrBtnBuscar = async () =>{

        setRecords([]);

        let fi = ""; 

        const fechIni = fechaInicial.substring(8, 10) + "/" + fechaInicial.substring(5, 7) + "/" + fechaInicial.substring(0, 4) ;           
        if (tiempoInicial === ""){
          fi = fechIni + " 00:00:00";            
        }else{
          fi = fechIni + " " + tiempoInicial + ":00";  
        }

        let ff = "";
        const fechFin = fechaFinal.substring(8, 10) + "/" + fechaFinal.substring(5, 7) + "/" +  fechaFinal.substring(0, 4) ;         
        if (tiempoInicial === ""){
          ff = fechFin + " 23:59:59";            
        }else{
          ff = fechFin + " " + tiempoFinal + ":00";  
        }
     
        let dts: string = "";
        almacenesSelected.map((dato: any)=>{
            dts = `${dato.cat};${dato.key.split(' - ')[0].trim()}-${dts}`;
        });
      
        dts = dts.substring(0, dts.length - 1);
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Kardex?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&CentroAlmacen=${dts}&fechaIni=${fi}&fechaFin=${ff}`;    
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);     
        const auxDta:any = [];
        if (response.code >= 400){
            setMsgRespuestInv(arrDta.Message);
            setMsgDlgShowApiResponse(true);
            setRecords(auxDta);              
        }
        else{
            if (arrDta.length > 0){  
                arrDta.map((valor: any, index: number)=>{
                    const newObj = {
                        PAIS:valor.PAIS,  
                        ANNO:valor.ANNO, 
                        MES:valor.MES,                       
                        CENTRO:valor.CENTRO,
                        ALMACEN:valor.ALMACEN,
                        INV_ITEM_ID:valor.INV_ITEM_ID,
                        ESPECIAL:valor.ESPECIAL,
                        DESCRIPCION:valor.DESCRIPCION,
                        UNIDAD:valor.UNIDAD,
                        FECHA_APERTURA:valor.FECHA_APERTURA,
                        HORA_APERTURA:valor.HORA_APERTURA,
                        FECHA_CIERRE:valor.FECHA_CIERRE,
                        HORA_CIERRE:valor.HORA_CIERRE,
                        CANTIDAD_SAP:valor.CANTIDAD_SAP,
                        CANTIDAD_CONTEO:valor.CANTIDAD_CONTEO,
                        DIFERENCIA:valor.DIFERENCIA,
                        CANTIDAD_AJUSTADA:valor.CANTIDAD_AJUSTADA,
                        FECHA_AJUSTE:valor.FECHA_AJUSTE,
                        CANTIDAD_INVENTARIO:valor.CANTIDAD_INVENTARIO,
                        VALOR_INVENTARIO:valor.VALOR_INVENTARIO,
                        FECHA_BAJADA_SALDOS: valor.FECHA_BAJADA_SALDOS,
                        HORA_BAJADA_SALDOS: valor.HORA_BAJADA_SALDOS,

                    }
                    auxDta.push(newObj);
                }); 
                //********Pasa el objeto a arreglo para recorrido en table
                setRecords(auxDta); 
                setCpRecords(auxDta);
                setBtnToExcel(false);
            }else{
                setBtnToExcel(true);
            }       
        }

        setFechaMin(`${formData.Anno}-${formData.Mes}-01`);
    }

    const MsgDlghandlerResponseApi = () =>{
        setMsgDlgShowApiResponse(false);
    }  

    const exportAExcell = (name: string, data: any) =>{

        exportToExcel(name, data)
    }

    const habilitaBuscar = (lng: number) => {
        
        setHbltBtnAbrir(!lng);

    }

    const onSelectedOptionCenter = (e: any) =>{
         
        if (e.length > 0){
            const almacenes: any = [];
            e.map((dato: any)=>{
                const alma = emp.almacenes.filter( (alm: string)=> alm.includes(dato.cat) );
                //organiza la estructura de almacens así: {cat: id, key: label} paramostrar en el multiselect
                alma.map((dt: string) => {
                    const cnt = dato.cat;
                    const almcn = `${dt.split(' - ')[1]} - ${dt.split(' - ')[2]}`; 
                    almacenes.push({cat : cnt, key : almcn});
                });
            });

            setAlmacenesFiltrados(almacenes);    
        }else{
            setAlmacenesFiltrados([]);            
        }

    }

    const onRemoveOptionCenter = (e: any, centRmv: any) =>{
        
        const almcremv = almacenesSelected.filter((dato: any) => dato.cat !== centRmv.cat);
        setAlmacenesSelected(almcremv);
        if (almcremv.length < 1){
            setAlmacenesFiltrados([]);
        }

        if (e.length > 0){
            const almacenes: any = [];
            e.map((dato: any)=>{
                const alma = emp.almacenes.filter( (alm: string)=> alm.includes(dato.cat) );
                //organiza la estructura de almacens así: {cat: id, key: label} paramostrar en el multiselect
                alma.map((dt: string) => {
                    const cnt = dato.cat;
                    const almcn = `${dt.split(' - ')[1]} - ${dt.split(' - ')[2]}`; 
                    almacenes.push({cat : cnt, key : almcn});
                });
            });

            setAlmacenesFiltrados(almacenes);    
        }else{
            setAlmacenesFiltrados([]);            
        }

        habilitaBuscar(almcremv.length);
    }
    
    const onSelectedOptionAlmacen = (e: any) =>{

        setAlmacenesSelected(e);
        habilitaBuscar(e.length);

    }

    const onRemoveOptionAlmacen = (e: any) =>{
        
        habilitaBuscar(e.length);
    }

    const handlerFechaI = (e: any) =>{
        
        setFechaInicial(e.target.value);
        setFechaFinal(e.target.value);  

    }

    const handlerFechaF = (e: any) =>{
        
        setFechaFinal(e.target.value);  

    }    
    
    const handlerTi = (e: any) =>{
        
        setTiempoInicial(e.target.value); 
        setHabilitaTiempoFinal(false) 

    }    

    const handlerTf = (e: any) =>{

        setTiempoFinal(e.target.value);  

    } 

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);    
                setHabilitaFechas(false);
                setPeriodo(false);    
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            } 
        }

        preFiltros();  

        const centros: any = [];
        emp.centros.map((dt: string) => {
            const cnt = dt.split(' - ')[0];
            const nmCnt = `${cnt} - ${dt.split(' - ')[1]}`; 
            centros.push({cat : cnt, key : nmCnt});
        });   
        setCentros(centros);

    }, []);   

    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Auditoria / Kardex Final
                </div>
                <div className="h4 mt-4 mb-4 ms-3">KARDEX FINAL</div>
            </div> 
            <Container fluid>
                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS KARDEX</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Período</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />
                            <div className="row">
                                <div  className="d-flex flex-column  col-lg-4 ">
                                    <label className="m-2 ">Mes/Año</label>
                                    <input type="month" id="periodo" size={8}  className="ms-2"
                                        onChange={hndlrPeriodo}
                                        value={`${formData.Anno}-${formData.Mes}`} 
                                        min="1990-01" max="9999-12"
                                    />
                                </div>
                                <div  className="d-flex flex-column  col-lg-4 ">
                                    <label className="m-2 ">Fecha ajuste desde</label>
                                    <input type="date" id="fechaInicial" size={8}  className="ms-2 mb-1 "
                                        onChange={handlerFechaI}
                                        value={fechaInicial} 
                                        min={fechaMin}
                                        disabled={habilitaFechas}

                                    />
                                    <input type="time" id="tiempoInicial" size={8}  className="ms-2 mt-1"
                                        onChange={handlerTi}
                                        value={tiempoInicial} 
                                        disabled={habilitaFechas}
                                    />                                    
                                </div> 
                                <div  className="d-flex flex-column  col-lg-4 ">
                                    <label className="m-2 ">Fecha ajuste hasta</label>
                                    <input type="date" id="fechaFinal" size={8}  className="ms-2 mb-1"
                                        onChange={handlerFechaF}
                                        value={fechaFinal} 
                                        min={fechaInicial}
                                        disabled={habilitaFechas}
                                    />
                                    <input type="time" id="tiempoFinal" size={8}  className="ms-2 mt-1"
                                        onChange={handlerTf}
                                        value={tiempoFinal} 
                                        min={tiempoInicial}
                                        disabled={habilitaFechas || habilitaTiempoFinal}
                                    />                                    
                                </div>    
                            </div>                                                   
                            <hr className="t-0 m-0 mt-3" />                            
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div>
                                        <Multiselect className="" aria-label="Default select example" 
                                            onSelect={(e: any)=>onSelectedOptionCenter(e)}
                                            onRemove={(e: any, remcnt: any)=>onRemoveOptionCenter(e, remcnt)}
                                            options={centros}
                                            displayValue="key"
                                            placeholder="Centros de distribución"
                                        />
                                    </div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div>
                                        <Multiselect className="" aria-label="Default select example" 
                                            onSelect={(e: any)=>onSelectedOptionAlmacen(e)}  
                                            onRemove={onRemoveOptionAlmacen}                                         
                                            options={almacenesFiltrados}
                                            displayValue="key"
                                            placeholder="Almacen(es)"
                                            selectedValues={almacenesSelected}
                                        />
                                    </div>
                                </Container>
                            </div>

                            <div className="text-end">
                                <Button type="button" className=" btn border border-0" 
                                    disabled={hbltBtnAbrir || periodo}  onClick={hndlrBtnBuscar} style={{backgroundColor: "#00B5B8"}}
                                >
                                    Buscar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>         

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">KARDEX</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="text-start mb-4">
                                    <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                        <label className="m-1">Filtro</label>
                                        <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                    </div>  
                                    <div className="m-1 col-md-12 col-lg-8 " > 
                                        <Button type="button" className="border-0" style={{backgroundColor:"#16D39A"}}
                                            disabled={btnToExcel}
                                            onClick={async ()=> exportAExcell("KardexFinal", records)}>
                                            Exportar
                                        </Button>
                                    </div>                                     
                            </div>
                            <DataTable
                                columns={columns}
                                data={records}
                                fixedHeader
                                pagination
                                highlightOnHover
                                fixedHeaderScrollHeight="600px"
                                paginationComponentOptions={pagOptions}                             
                            />    
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
            </Container>
            {/*********** cuadro de dialogo para msj de api **********/}
            <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Kardex Final`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApi}
                HanlerdClickNok={null}
            /> 

            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />              
        </Container>
    );
}

export default KardexFinalPage;


import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import * as env from '../../../../env';
import { httpApiGetText } from '../../../../lib';
import { exportToExcel } from '../../../../util';

const MsgDialogDetalleInventario = (
            props: {Show: boolean, Title: string, SubTitle: string 
            BtnOkName: string, BtnNokName: string, data:any, formData: any;
            HandlerClickOk: any, HanlerdClickNok: any}) =>{

  let [records, setRecords] = useState([]);
  let [cpRecords, setCpRecords] = useState([]);  
  const [sHCarga, setSHCarga] = useState(false);  

  let columns = [
    { name: "Cantidad", selector: (row:any) => row.Cantidad, format: (row:any) => row.Cantidad.toLocaleString(), sortable: true, right:true, width:"150px"},
    { name: "Serial", selector: (row:any) => row.Serial, sortable: true, width:"250px"},
    { name: "Esp", selector: (row:any) => row.Especial, sortable: true},
    { name: "Nro. docto.", selector: (row:any) => row.NroDocto,  sortable: true, width:"150px"},
    { name: "Pos. Docto.", selector: (row:any) => row.PosDocto, sortable: true, width:"150px"}, 
    { name: "Fecha", selector: (row:any) => row.Fecha, sortable: true, width:"150px"},
    { name: "Usuario", selector: (row:any) => row.Usuario, sortable: true, width:"150px"},
    { name: "Observación", selector: (row:any) => row.Observacion, width:"250px", sortable: true, wrap:true},        
  ]

  const pagOptions = {
      rowsPerPageText: "Filas por páginas",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos"
  }; 

  useEffect(()=>{

    const llamadaApi = async () =>{
      setSHCarga(true);
      const recurso = `${env.REACT_APP_API_URL}/api/ReportInventario?pais=CL&Anno=${props.formData.Anno}&Mes=${props.formData.Mes}` + 
                      `&Centro=${props.formData.Centro}&Almacen=${props.formData.Almacen}&Producto=${props.data.Codigo_Rosen}&Diferencias=0&Especial=`;  

      const response = await httpApiGetText(recurso, {
          'Content-Type': 'application/json',
          'Rosen-API-Key': '2B89CE9E-47DF-4EF1-8353-CF0C18EE029F',
          'Rosen-API-Secret': 'EFCFA469-8474-4DCF-B84A-CB1B0835ABDA-CDDC8DDB-6E6E-47CB-9422-B8A4312EC01A',
      });
      const arrDta = await JSON.parse(response.message); 
      const auxDta:any = [];
      arrDta.Movimientos.map((obj: any, index: number)=>{
        const newObj = {
          Cantidad: obj.Cantidad, 
          Serial : obj.Serial, 
          Especial : ((obj.Especial)?obj.Especial: ""),
          NroDocto: obj.NroDocto,
          PosDocto: obj.PosDocto,
          Fecha: obj.Fecha,
          Usuario: obj.Usuario,
          Observacion: obj.Observacion
        };
        auxDta.push(newObj);
      });
      setRecords(auxDta);
      setCpRecords(auxDta);
      setSHCarga(false);
    }

    if (props.Show){
      llamadaApi();
    }

    return ()=> {
        records = [];
    }
  }, [props.Show]);

const changeTextFiltro = (evnt: any) => {
    if (evnt.target.value.trim() === ""){
      setRecords(cpRecords);  
    }else{
        let reg = records.filter((dato: any)=> {
            return (dato.Cantidad && dato.Cantidad.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Serial && dato.Serial.includes(evnt.target.value.toUpperCase())) 
                || (dato.Especial && dato.Especial.includes(evnt.target.value.toUpperCase()))  
                || (dato.NroDocto && dato.NroDocto.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.PosDocto && dato.PosDocto.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Fecha && dato.Fecha.includes(evnt.target.value.toUpperCase())) 
                || (dato.Usuario && dato.Usuario.includes(evnt.target.value.toUpperCase()))
                || (dato.Observacion && dato.Observacion.includes(evnt.target.value.toUpperCase()));                        
        }); 
        setRecords(reg); 
    }
} 

return(
    <>
      <Modal show={props.Show} size="xl">
        <Modal.Header >
          <Modal.Title>{props.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>{`${props.data.Codigo_Rosen} - ${props.data.Descripcion}`}</div>
            <div className='mt-3'>
                <div>
                    <Button className="btn btn-success" onClick={()=>{ 
                            if (records.length > 0 )
                              exportToExcel("tablaAvanceDetalle", records)
                            }
                         }>Exportar
                    </Button>
                    <div className="d-flex flex-column col col-lg-6 mb-3 ">
                        <label className="m-1">Filtro</label>
                        <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                    </div>
                    <div>
                        <DataTable
                            columns={columns}
                            data={records}
                            selectableRowsHighlight
                            fixedHeader
                            pagination
                            highlightOnHover
                            fixedHeaderScrollHeight="600px"
                            paginationComponentOptions={pagOptions}
                        />                      
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button  onClick={props.HandlerClickOk} className="btn btn-success">
            {props.BtnOkName}
          </Button>          
          <Button onClick={props.HanlerdClickNok} className="btn btn-secondary">
            {props.BtnNokName}
          </Button>
        </Modal.Footer>
      </Modal>
      <LocalMsgModalSpinner 
          Show={sHCarga}
          text="Un momento por favor, verificando detalle..."
          color="#FF7588"
      />        
    </>
)

}

export default MsgDialogDetalleInventario;
